'use client';

import React, { useEffect, useState } from 'react';
import axios from "axios";
import { getCookie, getDomain } from "@/assets/ts/Utils";
import useUserStore from "@/store/user";
import { useSession } from "next-auth/react";

declare global {
  interface Window {
    HACKER_FAMILY_MENU: any;
  }
}

function extractScriptContent(str: string) {
  const regex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;
  const matches = [];
  let match;

  while ((match = regex.exec(str)) !== null) {
    const scriptTag = match[0];
    const content = scriptTag.replace(/<script\b[^>]*>/i, '').replace(/<\/script>/i, '');
    matches.push(content.trim());
  }

  return matches;
}

function HackerFamilySite() {
  const { status } = useSession();
  const storeUser = useUserStore(state => state.user);
  const [menuVisible, setMenuVisible] = useState(true);

  const handleToggleMenu = (state: boolean) => {
    setMenuVisible(state);
  };

  useEffect(() => {
    const fetchFamilySite = async () => {
      try {
        const response = await axios({
          url: `/integrationLNB/${process.env.NEXT_PUBLIC_SERVICE_ID}/lnb.html`,
          method: 'get',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded; charset=euc-kr",
          },
          responseType: 'arraybuffer'
        });

        const data = new TextDecoder('euc-kr').decode(new Uint8Array(response.data));

        const scripts = extractScriptContent(data);
        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = `${scripts[0]}`;
        document.body.appendChild(scriptElement);

        document.getElementById('lnb-wrap')!.innerHTML = data;
        window.HACKER_FAMILY_MENU.init();
      } catch (error) {
        console.error(error);
      }
    }

    fetchFamilySite();

    if (getCookie('_champ_lnb_hide') == '1') {
      handleToggleMenu(false);
    }
  }, []);

  return (
    <div>
      <div className="hackers-site-wrap" id="family_site" style={{ transform: `translateX(${menuVisible ? `0` : `-100%`})` }}>
        <div className="hackers-site-header">
          <button className="family-menu-close" type="button" onClick={() => handleToggleMenu(false)}>접기</button>
          {status === 'authenticated' &&
            <strong className="text">
              <em>{storeUser?.name}</em>님,<br />응원합니다!
            </strong>
          }
          {status === 'unauthenticated' &&
            <div className="t_c">
              <strong className="title">
                Hackers Family site
                {/* <span className="basis_text">헤럴드 선정 2018 대학생 선호 브랜드 대상 &  &quot;취업 강의 &quot; 부문 1위</span> */}
              </strong>
              {/* <strong className="text ft14">
                <a href="">
                  회원가입만 해도
                  <span className="point">선물이 팡팡!!</span>
                </a>
              </strong> */}
            </div>
          }
        </div>
        <ul className="hackers-site-myInfo">
          {status === 'authenticated' &&
            <>
              <li><a href={`https://${process.env.NEXT_PUBLIC_ENV || ""}myclass.hackers.com/room?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`}><i className="ico-family mypage"></i>마이클래스</a></li>
              <li><a href={`https://${process.env.NEXT_PUBLIC_ENV || ""}bill.hackers.com/cart?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`}><i className="ico-family cart"></i>장바구니</a></li>
              <li><a href={`https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/logout?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.href)}`}><i className="ico-family lock"></i>로그아웃</a></li>
            </>
          }
          {status === 'unauthenticated' &&
            <>
              <li><a href={`https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/join?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`}><i className="ico-family join"></i>회원가입</a></li>
              <li><a href={`https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/login?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.href)}`}><i className="ico-family lock"></i>로그인</a></li>
            </>
          }
          <li><a href="/cs/wishHackers"><i className="ico-family send"></i>해커스에 바란다</a></li>
        </ul>
        <div id="lnb-wrap" />
      </div>
      <div className="hackers-site-wrap close" id="family_site_open" style={{ transform: `translateX(${menuVisible ? `-100%` : `0`})` }}>
        <div className="hackers-site-header">
          <button type="button" className="family-menu-open" onClick={() => handleToggleMenu(true)}>열기</button>
          <strong className="title-close">패밀리사이트</strong>
        </div>
      </div>
    </div>
  );
}

export default HackerFamilySite;