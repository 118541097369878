import { APIReturnType } from "@/interface/api";
import ApiClient from "@/lib/ApiClient";
import useUserStore from "@/store/user";
import axios from "axios";
import { useSession } from "next-auth/react";
import { usePathname, useRouter } from "next/navigation";
import { UseQueryOptions, useMutation, useQuery } from "react-query";

interface Params {
  ServiceID: any;
  URI: string;
  Domain: string;
  tempTime?: string;
}

interface CouponParams {
  issueReasonCCD: number;
  issueReasonValue: number;
  memberIdx: string;
  pinNo?: string;
  evtId?: string;
}

const fetchClosingBanner = async (params?: Params): Promise<APIReturnType> => {
  const { data } = await axios.get("/v1/billing/closingbanner", {
    headers: {
      Accept: "application/json",
      apiKey: "Api@Key.Hackers",
      token: "Api@Token.Hackers",
      "x-api-key": process.env.NEXT_PUBLIC_GATEWAY_KEY || ""
    },
    params: params,
  });

  return data;
};

export const useClosingBanner = (
  params: Params,
  options?: UseQueryOptions<APIReturnType>,
) => {
  return useQuery<APIReturnType>(
    ["banner", params],
    () => fetchClosingBanner(params),
    options,
  );
};

export const fetchPaymentCoupon = async ({
  memberIdx,
  issueReasonCCD,
  issueReasonValue,
  pinNo,
  evtId, // 이벤트 코드: 중복 발급 시 필수
}: CouponParams) => {
  const api = new ApiClient();
  const { data } = await api.post(
    `/coupon/member/${memberIdx}/pin_no/${pinNo}`,
    { issueReasonCCD, issueReasonValue, evtId },
  );

  return data;
};

// 통합 쿠폰 지급
export const usePaymentCoupon = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { status } = useSession();
  const storeUser = useUserStore((state) => state.user) || {
    id: "",
    idx: "",
    name: "",
  };

  const isLogin = storeUser.idx && status === "authenticated";

  const couponPayment = async (pinNo: string[], eventCode?: string) => {
    if (!isLogin) {
      router.push(`/auth/checkAuth?redirect=${pathname}`);
      return false;
    }

    const response: boolean[] = [];

    await Promise.all(
      (pinNo || []).map(async (pin) => {
        const res = await fetchPaymentCoupon({
          memberIdx: storeUser.idx,
          issueReasonCCD: 110,
          issueReasonValue: 0,
          pinNo: pin,
          evtId: eventCode, 
        });

        response.push(res.code === "0000");
      })
    );

    return !response.includes(false);

    // if (response.includes(false)) {
    //   alert("쿠폰번호가 올바르지 않거나,\n이미 등록된 쿠폰번호입니다.");
    //   return false;
    // } else {
    //   alert(successMsg || "쿠폰이 지급되었습니다.");
    //   return true;
    // }
  };

  return { couponPayment };
};

const fetchMemberBenefit = async (
  MemberIdx: string,
): Promise<APIReturnType> => {
  const api = new ApiClient();
  const { data } = await api.get("/migration/member/benefit", {
    params: { MemberIdx },
  });

  return data;
};

export const useMemberBenefit = (
  MemberIdx: string,
  options?: UseQueryOptions<APIReturnType>,
) => {
  return useQuery<APIReturnType>(
    ["member", MemberIdx],
    () => fetchMemberBenefit(MemberIdx),
    options,
  );
};

const fetchGetMemberMocktestProduct = async (params: {
  MemberIdx: string;
  MemberMockTestIdx: string;
}): Promise<APIReturnType> => {
  const api = new ApiClient();
  const { data } = await api.get("/mocktest/getMemberMocktestProduct", {
    params: { ...params, ServiceID: process.env.NEXT_PUBLIC_SERVICE_ID },
  });

  return data;
};

export const useGetMemberMocktestProduct = (
  params: {
    MemberIdx: string;
    MemberMockTestIdx: string;
  },
  options?: UseQueryOptions<APIReturnType>,
) => {
  return useQuery<APIReturnType>(
    ["mockTestProduct", params],
    () => fetchGetMemberMocktestProduct(params),
    options,
  );
};
