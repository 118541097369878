"use client";

import styles from "./Header.module.scss";

import { useState } from "react";

import { useGate } from "@/hook/useCommon";

import { Button } from "../button";
import Link from "next/link";

export default function HackersGate() {
  const { data, isLoading } = useGate();

  const [isOpen, setIsOpen] = useState(false);
  const [isShowReason, setIsShowReason] = useState(false);

  const [topTabIdx, setTopTabIdx] = useState(0);

  if (isLoading || (data?.gateInfo && data.gateInfo?.IsUse === 0)) return <></>;

  return (
    <div className={styles.hackersGate}>
      <>
        <div className={`inner ${styles.top}`}>
          <div className={styles.topLeft}>
            <p>{data?.gateInfo?.GateMainWord}</p>
            <Button
              width={30}
              height={30}
              variant={isOpen ? "arrow-up" : "arrow-down"}
              onClick={() => setIsOpen(!isOpen)}
              className={styles.button}
            />
          </div>

          <div className={styles.topRight}>
            {
              data?.gateInfo?.EvidenceWord && (
                <>
                  <p onClick={() => setIsShowReason(!isShowReason)}>근거문구 보기</p>
                  {
                    isOpen &&
                    <Button
                      width={30}
                      height={30}
                      variant="close"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setIsShowReason(false);
                      }}
                      className={styles.button}
                    />
                  }
                </>
              )
            }
          </div>
        </div>

        {
          isShowReason &&
          <div className={styles.tooltip}>
            {data?.gateInfo?.EvidenceWord}
          </div>
        }
      </>

      <div
        className={`inner_wide ${styles.bottom}`}
        style={{
          height: isOpen ? "250px" : "0",
          padding: isOpen ? "10px 0" : "0",
          borderTop: isOpen ? "1px solid #c4c4c4" : "none",
        }}
      >
        <div className="inner">
          <ul className={styles.gateTab}>
            {data?.gateCategory?.map((gateCategory, gateTopIdx) => (
              <li
                className={`${topTabIdx === gateTopIdx && styles.active}`}
                key={gateCategory.CategoryTopIdx}
                onClick={() => setTopTabIdx(gateTopIdx)}
              >
                <span>{gateCategory.CategoryTopName}</span>
              </li>
            ))}
          </ul>

          <ul className={styles.content}>
            {
              data?.gateCategory[topTabIdx]?.CategoryMid?.map(gateCategoryMid => (
                <li key={gateCategoryMid.CategoryMidIdx}>
                  <p>{gateCategoryMid.CategoryMidName}</p>
                  <ul>
                    {gateCategoryMid.CategoryMidMapper.map(midMapper => (
                      <li key={midMapper.CategoryMidIdx}>
                        <Link href={midMapper.GateWebLandingURL} target="_blank">
                          {midMapper.GateName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
}