"use client";

import { ComponentProps, forwardRef, useState } from "react";
import Image from "next/image";

type TProps = ComponentProps<typeof Image> & {
  src: string;
  alt: string;
};
/**
 * StrictImage
 * 
 * @description next/image를 사용할 때, 이미지 주소 검증이 필요할 경우 사용
 * @property {string} src 이미지 주소
 * @property {string} alt 이미지 설명
 * @property {} ...props next/image props
 * @example
 * <StrictImage src={3333} alt={"alt"} />
 */
const StrictImage = forwardRef<HTMLImageElement, TProps>(({ src = "", alt, ...props }, ref) => {
  const checkSrc = src.startsWith("//") ? src.replace("//", "https://") : src;

  const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  const isValidSrc = new RegExp(urlRegex).test(checkSrc);

  const [isErr, setIsErr] = useState(false);

  if (!isValidSrc || isErr) {
    return (
      <div
        style={
          {
            width: props?.width,
            height: props?.height
          }
        }
      />
    )
  }

  return <Image {...props} src={checkSrc} alt={alt} ref={ref} onError={() => setIsErr(true)} />
});

StrictImage.displayName = "StrictImage";

export default StrictImage;