"use client";

import { ChangeEvent, ComponentPropsWithRef, forwardRef } from "react";
import styles from "./checkinput.module.scss";
import styled from "styled-components";

type propsType = {
  changed?: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  isSelected?: boolean;
  label: string | React.ReactNode;
  value: string;
  theme?: string;
  types: string;
  name: string;
  dataDepth?: number;
  color?: string;
} & ComponentPropsWithRef<"input">;

/**
 * checkbox,radio button
 *
 * @property {"default" |  "circle" | "square" | "customCircle" | "customSquare" } theme 체크박스 테마설정
 * @example
 * <CheckButton  types="radio" theme="square" id="ck_01" name="check_01" lable="텍스트" value="11">
 *    button
 * </CheckButton>
 */

export const CheckButton = forwardRef<HTMLInputElement, propsType>((props, ref) => {
  const {
    changed,
    id,
    isSelected,
    label,
    value,
    theme = "default",
    types,
    name,
    dataDepth,
    color,
    ...rest
  } = props;

  return (
    <div className="RadioButton">
      <Label
        style={!label ? { marginRight: 0 } : {}}
        className={`${styles.inputSp} ${theme ? theme : ""} ${color ? styles.customInput : ""}`}
        htmlFor={id}
        $color={color}
      >
        <input
          name={name}
          type={types}
          id={id}
          value={value}
          checked={isSelected}
          onChange={changed}
          data-depth={dataDepth}
          {...rest}
        />
        <i></i>
        <span className="input-txt">{label}</span>
      </Label>
    </div>
  );
});

CheckButton.displayName = "CheckButton";

const Label = styled.label<{ $color?: string }>`
  input[type="checkbox"]:checked + i,
  input[type="radio"]:checked + i {
    background-color: ${(props) => props.$color};
  }
`;
