import { useQuery } from "react-query";

import { fetchBanners } from "./fetch/fetchCommon";

interface ListParams {
  Depth1: string;
  Depth2?: string;
  Depth3?: string;
  Depth4?: string;
}

export const bannerKeys = {
  all: ["banner"] as const,
  list: (params: ListParams) => [...bannerKeys.all, { ...params }] as const,
};

export const useBanners = (filter: any, sort?: any) => {
  return useQuery([bannerKeys.list(filter), filter, sort], () =>
    fetchBanners(filter, sort)
  );
};
