"use client";

import Script from "next/script";

export default function Analytics() {
  const prod = (process.env.NEXT_PUBLIC_ENV || "") == '';

  return (
    <>
      {/* <Script
        id="gtm-noscript"
        strategy="lazyOnload"
      >
        {` */}
      <div style={{ display: 'none', visibility: 'hidden' }}>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=GTM-${prod ? 'TXTMSBPV' : 'M2W454W9'}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      </div>
      {/* `}
      </Script> */}
      {/* <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=GTM-${prod ? 'TXTMSBPV' : 'M2W454W9'}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript> */}
    </>
  );
}