import { create } from "zustand";

export interface User {
  idx: any;
  name: string;
  id: string;
  email: string;
  tel: string;
  birth: string;
  teacherName?: string;
  teacherService?: Array<any>;
}
interface State {
  user: User | null;
  setUser: (param: User | any) => void;
}

const useUserStore = create<State>((set) => ({
  user: {
    idx: "",
    name: "",
    id: "",
    email: "",
    tel: "",
    birth: "",
  },
  setUser: (param: User | null) =>
    set((state) => ({
      user: param,
    })),
}));

export default useUserStore;
