"use client";

import styles from "./Header.module.scss";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { getDomain } from "@/assets/ts/Utils";
import useUserStore from "@/store/user";
import { usePathname, useRouter } from "next/navigation";

export default function HeaderTop() {
  const { data, status } = useSession();
  const storeUser = useUserStore((state) => state.user);
  const pathName = usePathname();

  return (
    <div className={`${styles.headerTopWrap}`}>
      <div className={`${styles.headerTopInnerWrap} inner`}>
        <ul className={`${styles.leftMenu}`}>
          <li>
            <Link href={`https://${process.env.NEXT_PUBLIC_ENV || ""}law.hackers.com/?r=lawyer&g_id=46`} target="_blank">해커스 노무사 &#183; 변호사</Link>
          </li>
          <li>
            <Link href={`https://${process.env.NEXT_PUBLIC_ENV || ""}ecpa.hackers.com/`} target="_blank">해커스 회계사 &#183; 세무사</Link>
          </li>
        </ul>

        <ul className={`${styles.rightMenu}`}>
          {status === "authenticated" && (
            <>
              <li>
                <Link
                  href={`https://${process.env.NEXT_PUBLIC_ENV || ""}myclass.hackers.com/myinfo?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`}
                  title="나의정보"
                  target="_blank"
                  className={styles.join}
                >
                  {storeUser?.idx === process.env.NEXT_PUBLIC_MANAGER
                    ? "해커스 감정평가사님"
                    : "나의정보"}
                </Link>
              </li>
              <li>
                <Link
                  href={`https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/logout?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.origin || getDomain())}`}
                  title="로그아웃"
                >
                  로그아웃
                </Link>
              </li>
            </>
          )}
          {status === "unauthenticated" && (
            <>
              <li>
                <Link
                  href={`https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/login?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.href || getDomain() + pathName)}`}
                  title="로그인"
                >
                  로그인
                </Link>
              </li>
              <li>
                <Link
                  href={`https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/join?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(window.location.href || getDomain() + pathName)}`}
                  title="회원가입"
                  className={styles.join}
                >
                  회원가입
                </Link>
              </li>
            </>
          )}
          <li>
            <button
              type="button"
              title="고객센터"
              onClick={() => (window.location.href = "/cs/faq")}
            >
              고객센터
            </button>
          </li>
          <li>
            <Link
              href={`https://${process.env.NEXT_PUBLIC_ENV || ""}bill.hackers.com/cart?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`}
              title="장바구니"
            >
              장바구니
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
