"use client";

import styles from "./Header.module.scss";
import Slider, { LazyLoadTypes, Settings } from "react-slick";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useDDay } from "@/hook/useCommon";

// 클라이언트와 서버 시간을 일치시키기 위해 설정 (React18)
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");
dayjs.locale("ko");

export default function DDay() {
  const { data: dDayData, isSuccess } = useDDay({});
  const today = dayjs().format("YYYY-MM-DD");

  const settings: Settings = {
    dots: false,
    arrows: false,
    lazyLoad: "ondemand" as LazyLoadTypes,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
  };

  return (
    <Slider {...settings} className={styles.dDayWrap}>
      {dDayData?.items?.
        filter((item) => dayjs(item.ddayDate).diff(today, "day") >= 0)
        .map((item: any) => {
          const diffDay = dayjs(item.ddayDate).diff(today, "day");
          return (
            <div className={styles.slideItemBox} key={item.id}>
              <div className={styles.slideItem}>
                <span className={styles.dDay}>
                  {diffDay > 0
                    ? `D-${diffDay}`
                    : `D-Day`}
                </span>
                <div className={styles.examInfoBox}>
                  {item.ddayDate}
                  <br />
                  {item.title}
                </div>
              </div>
            </div>
          );
        })}
    </Slider>
  );
}
