import { MapLink } from "@/interface/common";
import React from "react";
import StrictImage from "./StrictImage";
import Link from "next/link";

interface TProps {
  src: string;
  name: string;
  mapLinks: MapLink[];
  setMovieUrl: React.Dispatch<React.SetStateAction<string>>;
  movieRef: React.MutableRefObject<any>;
}

export default function MapImage({ src, name, mapLinks, setMovieUrl, movieRef }: TProps) {
  return (
    <div style={{ position: "relative", fontSize: 0 }}>
      <StrictImage alt={name || ""} src={src} width={0} height={0} style={{ width: "auto", height: "auto" }} />

      {mapLinks &&
        mapLinks?.map((link: any, index: number) => {
          const imageLeft = +link.coords.split(",")[0];
          const imageTop = +link.coords.split(",")[1];
          const imageWidth = +link.coords.split(",")[2] - imageLeft;
          const imageHeight = +link.coords.split(",")[3] - imageTop;

          return (
            <Link
              key={`${link.linkURL}_${index}`}
              href={link.linkURL || ""}
              target={(link?.target !== "_video" && link?.target) || "_blank"}
              onClick={(e) => {
                if (link?.target === "_video") {
                  e.preventDefault();

                  setMovieUrl(link.linkURL);
                  movieRef.current.setIsShow(true);
                }
              }}
              style={{
                display: "block",
                position: "absolute",
                top: imageTop,
                left: imageLeft,
                width: imageWidth,
                height: imageHeight,
                fontSize: 0,
                zIndex: 1,
              }}
            >
              {link.linkAlt}
            </Link>
          );
        })}
    </div>
  );
}
