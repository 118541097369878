import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MoviePlayer from "@/components/player/ReactPlayer";
import Modal from "@/components/modal/Modal";

const MovieWrap = styled.div`
  position: relative;
  width: 100%;
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  i {
    display: block;
    width: 23px;
    height: 23px;
    text-align: right;
    background: url(${`${process.env.NEXT_PUBLIC_S3_IMG_URL}/common/sp_btn.png`}) no-repeat;
    background-position: -125px -90px;
  }
`;

function MovieModal({
  movieRef,
  movieUrl,
  width,
  height,
}: {
  movieRef: React.MutableRefObject<any>;
  movieUrl?: string;
  width?: number | string;
  height?: number | string;
}) {
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    if (!movieUrl) {
      setAutoPlay(false);
      return;
    }

    setAutoPlay(true);
  }, [movieUrl]);

  return (
    <Modal ref={movieRef} popName="">
      <MovieWrap>
        <CloseBtn>
          <button
            type="button"
            onClick={() => {
              movieRef.current.setIsShow(false);
            }}
          >
            <i></i>
          </button>
        </CloseBtn>
        <div style={{ width: width || '100%', height: height || '395px' }}>
          <MoviePlayer url={movieUrl} autoPlay={autoPlay} />
        </div>
      </MovieWrap>
    </Modal>
  );
}

export default MovieModal;
