"use client";

import Link from "next/link";
import styles from "./Header.module.scss";
import Slider, { LazyLoadTypes, Settings } from "react-slick";
import StrictImage from "@/components/image/StrictImage";

export default function MiniBanner({ miniBannerData }: { miniBannerData?: any }) {
  const settings: Settings = {
    dots: false,
    arrows: false,
    lazyLoad: "ondemand" as LazyLoadTypes,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
  };

  return (
    <Slider {...settings} className={styles.miniBanner}>
      {miniBannerData?.items?.map((item: any) => {
        return (
          <div key={item.id}>
            <Link href={item.linkURL} target="_blank">
              <StrictImage src={item.viewImageURL} alt={item.imageAlt} width={120} height={60} priority />
            </Link>
          </div>
        );
      })}
    </Slider>
  );
}
