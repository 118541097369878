"use client";

import React from "react";
import styles from "./Header.module.scss";
import Link from "next/link";
import Image from "next/image";
import MiniBanner from "./MiniBanner";
import DDay from "./DDay";
import { useBanners } from "@/hook/useBanner";

function TopNavi() {
  const { data: bannerData, isSuccess } = useBanners(
    {
      bannerClassCodeDepth3: { eq: "메인" },
      bannerClassCodeDepth4: { eq: "상단_미니" },
    },
    { field: "sortValue", direction: "desc" }
  ) || { items: [] };

  return (
    <div className={styles.topNavi}>
      <div className={`inner pos_r ${styles.topNaviWrap} `}>
        <h1 className={styles.logo}>
          <Link href={{ pathname: "/" }}>
            <Image
              src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/common/img_logo_ca.png`}
              alt={"로고"}
              width="229"
              height="27"
              quality={100}
              priority
            />
          </Link>
        </h1>

        <div className={styles.sideBanner}>
          <ul className={`${styles.familyLink}`}>
            <li className={styles.active}>
              <Link href={"/"}>인강</Link>
            </li>
            <li>
              <Link href={`https://${process.env.NEXT_PUBLIC_ENV || ""}caac.hackers.com/`}>학원</Link>
            </li>
          </ul>
          {/* 상단 미니 배너 */}

          <div className={`${styles.rightBannerWrap}`}>
            <DDay />
            {isSuccess && bannerData && bannerData?.items.length > 0 && (
              <ul className={`${styles.headerMiniBanner}`}>
                <MiniBanner miniBannerData={bannerData} />
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNavi;
