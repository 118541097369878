/**
 * 암호화 클래스 (AES-256 알고리즘)
 */
export const Crypto = require("crypto");

class CryptoAES {
  type: "LOGIN" | "EVENT" = "LOGIN";

  constructor(type?: "LOGIN" | "EVENT") {
    this.type = type ?? "LOGIN";
  }

  /**
   * 암호화 함수
   * @param {string} plainText 암호화 시킬 문자열
   * @returns 암호화된 값
   */
  encryptToken(plainText: string) {
    const iv = Crypto.randomBytes(16);
    const cipher = Crypto.createCipheriv(
      "aes-256-cbc",
      Buffer.from(this.type === "LOGIN" ? process.env.NEXT_PUBLIC_LOGIN_KEY || "" : process.env.NEXT_PUBLIC_EVENT_KEY || ""),
      iv,
    );
    let token = cipher.update(plainText);
    return (
      iv.toString("hex") +
      ":" +
      Buffer.concat([token, cipher.final()]).toString("hex")
    );
  }

  /**
   * 복호화 함수
   * @param {string} token 복호화 시킬 암호값
   * @returns 복호화된 문자열
   */
  decryptToken(token: string) {
    if (!token) {
      return "{}";
    }
    let textParts: any = token.split(":");
    const iv = Buffer.from(textParts.shift(), "hex");
    const encryptedText = Buffer.from(textParts.join(":"), "hex");
    const decipher = Crypto.createDecipheriv(
      "aes-256-cbc",
      Buffer.from(this.type === "LOGIN" ? process.env.NEXT_PUBLIC_LOGIN_KEY || "" : process.env.NEXT_PUBLIC_EVENT_KEY || ""),
      iv,
    );
    const decrypted = decipher.update(encryptedText);
    return Buffer.concat([decrypted, decipher.final()]).toString();
  }
}

export default CryptoAES;
