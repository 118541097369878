import React, { PropsWithChildren, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import styles from './modal.module.scss';
import { getCookie, setCookie } from "@/assets/ts/Utils";

interface Props {
  popName: string;
  showPeriod?: number;
  className?: string;
}

/**
 * Modal
 * 
 * @property {useRef} ref 모달 useRef 객체
 * @property {string} popName 팝업명
 * @property {number} showPeriod 게시 기간
 * @property {string} className 클래스명
 * @example 
 * const modalRef = useRef<any>();
 * <Modal ref={modalRef} popName="">
 * // 모달 컨텐츠
 * </Modal>
 * modalRef.current.setIsShow(value);
 */
const Modal = forwardRef(({ children, popName, showPeriod = 1, className }: PropsWithChildren<Props>, ref) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setIsShow,
    noMore,
  }));

  const noMore = useCallback(() => {
    setCookie(popName, 'true', showPeriod);
    setIsShow(false);
  }, [popName, showPeriod]);

  useEffect(() => {
    if (popName && !getCookie(popName)) {
      setIsShow(true);
    }
  }, [popName]);

  return (
    <>
      {isShow &&
        <div className={`popup ${className} ${styles.modal}`}>
          <div className={styles.bg} onClick={() => setIsShow(false)}></div>
          <div className={styles.inner}>
            {children}
          </div>
        </div>
      }
    </>
  );
});

Modal.displayName = 'Modal';

export default Modal;