import Script from "next/script";
import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import styles from "./button.module.scss"

interface propsType{
  functionLeft: any;
  titleLeft?: string;
  colorLeft?:string;
  functionRight: any;
  titleRight?: string;
  colorRight?:string;
}


function ButtonType1(props: propsType) {


  return (
    <div className={styles.type1}>
      <button  
      style={props.colorLeft ? {backgroundColor: props.colorLeft } : {backgroundColor: "#333"}}
      onClick={props.functionLeft}
      >
        {props.titleLeft ? props.titleLeft : "버튼명을입력하세요."}
      </button>
      <button 
        style={props.colorRight ? {backgroundColor: props.colorRight } : {backgroundColor: "#333"}}
        onClick={props.functionRight}
        >
          {props.titleRight ? props.titleRight : "버튼명을입력하세요."}
        </button>
    </div>
  );
}

export default ButtonType1;