import React from "react";
import dynamic from 'next/dynamic';

interface PlayerType {
  width?: string;
  height?: string;
  controls?: boolean;
  url?: string;
  autoPlay?: boolean;
  pause?: boolean;
  pip?: boolean;
  light?: string | boolean;
}


/**
 * MoviePlayer
 * 
 * @todo react 영상 플레이어
 * @description 플레이어
 * @property {width?: string;height?: string;controls?: boolean;url?: string;autoPlay?: boolean;pip?: boolean;light?: string | boolean;} PlayerType 배너 리스트
 * @example <MoviePlayer url="https://cdndown~" width="500px" height="300px" ~~/>
 */


const MoviePlayer = (props: PlayerType) => {
  const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });
  /**    https://cdn.hackers.com/gosi_m/images/event_new/EG2022113000/cover02.jpg   */
  
  if (props.url?.includes("tv.naver.com")) {
    return (
      <iframe src={props.url} allow='autoplay' width="100%" height={props.height ?? "100%"} allowFullScreen></iframe>
    )
  } else {
    return (
      <ReactPlayer
        playing={props.pause ? false : props.autoPlay ? true : false}
        url={props.url || ""}
        controls={props.controls == false ? props.controls : true}
        width={props.width ? props.width : "100%"}
        height={props.height ? props.height : "100%"}
        pip={props.pip ? props.pip : true}
        muted={props.autoPlay ? true : false}
        // onStart={() => console.log('미디어시작')}
        volume={0.7}
        light={props.light ? props.light : false}  //"https://cdn.hackers.com/gosi_m/images/event_new/EG2022113000/cover02.jpg"
      />
    )
  }
}

// 부모의 리렌더링에 의한 단순 리렌더링 방지하기 위함
export default React.memo(MoviePlayer);
