"use client";

import React from "react";
import styles from "./Header.module.scss";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { MenuItem } from "@/interface/common";

function Gnb({data}: {data: MenuItem[]}) {
  const fullPathName = usePathname();
  const pathname = usePathname().split("/")[1];

  if (fullPathName == "/event/240199") {
    return null;
  }
  return (
    <div className={`${styles.gnbWrap}`}>
      <div className="inner">
        <ul className={`${styles.depth1}`}>
        {data
            .filter((item: MenuItem) => item.depth == 0)
            .map((menu, idx) => (
              <li
                key={`menu_${idx}`}
                className={`${
                  "/" + pathname === menu.linkURL ? styles.on : ""
                }`}
              >
                <Link
                  className={styles.depth1Link}
                  href={{ pathname: menu.linkURL }}
                  target={menu.target}
                  prefetch={false}
                >
                  {menu.name}
                </Link>
                {data.filter((menu_2) => menu_2.parentId === menu.id).length >
                  0 && (
                  <ul className={styles.depth2}>
                    {data
                      .filter((menu_2) => menu_2.parentId === menu.id)
                      .map((depth_2) => (
                        <li key={depth_2.id}>
                          <Link href={depth_2.linkURL} target={depth_2.target}>
                            {depth_2.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
          {data && (
            <li>
              <Link
                className={styles.depth1Link}
                href={`/auth/checkAuth/myClass?redirect=https://${process.env.NEXT_PUBLIC_ENV || ""}myclass.hackers.com/room?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`}
              >
                마이클래스
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Gnb;
