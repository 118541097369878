"use client";

import Link from "next/link";
import styled, { keyframes, css } from "styled-components";
import { Fragment, MouseEvent, useEffect, useRef, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import StrictImage from "../image/StrictImage";
import MapImage from "../image/MapImage";
import { Button } from "../button";
import { CheckButton } from "../input/CheckInput";
import { getCookie, setCookie } from "@/assets/ts/Utils";
import { usePopupBanners } from "@/hook/useCommon";
import MovieModal from "@/components/modal/MovieModal";

/**
 * PopupBanner
 *
 * @description 팝업 배너
 * @example
 * <PopupBanner />
 *
 */
export default function PopupBanner(initialData: any) {
  const popupBannerRef = useRef<any[]>([]);
  const [isMount, setIsMount] = useState(false);
  const [movieUrl, setMovieUrl] = useState("");

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const movieRef = useRef<any>();

  const { data: items, isFetched } = usePopupBanners(
    {
      exposureLocation: { eq: pathname },
    },
    [],
    { placeholderData: initialData.initialData, keepPreviousData: true }
  );

  const renderImage = (item: any) => (
    <Link
      href={item?.linkURL || ""}
      target={(item?.bannerTargetCode !== "_video" && item?.bannerTargetCode) || "_blank"}
      onClick={(e) => {
        if (item?.bannerTargetCode === "_video") {
          e.preventDefault();
          setMovieUrl(item.linkURL || "");
          movieRef.current.setIsShow(true);
        }
      }}
    >
      <StrictImage
        src={item.viewImageURL || item.viewIFrameURL || ""}
        alt={item.title}
        width={0}
        height={0}
        style={{ width: "100%", height: "auto" }}
        priority
        unoptimized
      />
    </Link>
  );

  const handleNotTodayClick = (e: any, item: any, idx: number) => {
    e.preventDefault();
    if (popupBannerRef.current) {
      setCookie(`popupBanner_${item.id}`, "todayClose", 1);
      popupBannerRef.current[idx].style.display = "none";
    }
  };

  const handleCloseClick = (e: any, idx: number) => {
    e.preventDefault();
    if (popupBannerRef.current) {
      popupBannerRef.current[idx].style.display = "none";
    }
  };

  // 쿠키를 가져올 때 서버사이드에서 document undefined로 읽히지 않게 하기 위함
  useEffect(() => {
    if (isFetched) {
      setIsMount(true);
    }
  }, [isFetched]);

  if (!isMount) return null;

  return items?.map(
    (item, idx) =>
      !getCookie(`popupBanner_${item.id}`) &&
      item.exposureLocation?.includes(
        `${pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ""}`
      ) && (
        <Fragment key={item.id}>
          <Wrapper
            $isShake={item.isShake}
            $isAlignCenter={item?.isAlignCenter || false}
            ref={(el) => {
              popupBannerRef.current && (popupBannerRef.current[idx] = el as any);
            }}
            style={{
              position: (item.bannerFunctionType || []).includes("fixed") ? "absolute" : "fixed",
              top: `${item.displayLocationY}${item.isCalcRelative ? "%" : "px"}`,
              left: `${item.displayLocationX}${item.isCalcRelative ? "%" : "px"}`,
            }}
          >
            {item?.isAlignCenter ? (
              // 중앙 딤배너
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.6)",
                  }}
                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                    handleCloseClick(e, idx);
                  }}
                ></div>

                <ContentBox>
                  {(item.mapLinks || []).length > 0 ? (
                    <MapImage
                      name={item.title}
                      src={item.viewImageURL || item.viewIFrameURL || ""}
                      mapLinks={item.mapLinks || []}
                      setMovieUrl={setMovieUrl}
                      movieRef={movieRef}
                    />
                  ) : (
                    <>{renderImage(item)}</>
                  )}
                  {/* 하단 닫기 옵션 */}
                  {item.bannerFunctionType?.includes("close") && (
                    <BannerFunctionList>
                      {item.bannerFunctionType?.includes("notToday") && (
                        <li
                          onClick={(e: MouseEvent<HTMLLIElement>) => {
                            handleNotTodayClick(e, item, idx);
                          }}
                        >
                          <CheckButton
                            id="notToday"
                            label="오늘 하루 보지 않기"
                            name="bannerFunctionType"
                            types="checkbox"
                            value="notToday"
                            theme="square"
                          />
                        </li>
                      )}
                      <li
                        onClick={(e: MouseEvent<HTMLLIElement>) => {
                          handleCloseClick(e, idx);
                        }}
                      >
                        <CheckButton
                          id="close"
                          label="닫기"
                          name="bannerFunctionType"
                          types="checkbox"
                          value="close"
                          theme="square"
                        />
                      </li>
                    </BannerFunctionList>
                  )}
                </ContentBox>
              </>
            ) : (
              // 일반배너
              <>
                {item.bannerFunctionType?.includes("close") && (
                  <Button
                    width={20}
                    height={20}
                    theme="eventBtn"
                    style={{ marginLeft: "auto", marginBottom: "5px" }}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      handleCloseClick(e, idx);
                    }}
                  >
                    <StrictImage
                      src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/common/close_btn_v2.png`}
                      alt="closeBtn"
                      width={20}
                      height={20}
                    />
                  </Button>
                )}

                {renderImage(item)}
              </>
            )}
          </Wrapper>

          {/* 영상 팝업 */}
          {item?.bannerTargetCode === "_video" && (
            <div style={{ position: "absolute", zIndex: "1005" }}>
              <MovieModal movieRef={movieRef} movieUrl={movieUrl} />
            </div>
          )}
        </Fragment>
      )
  );
}

const shake = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
`;

const Wrapper = styled.div<{ $isShake: boolean; $isAlignCenter: boolean }>`
  width: fit-content;
  height: fit-content;
  z-index: 100;
  animation: ${(props) =>
    props.$isShake
      ? css`
          ${shake} 1s ease-in-out infinite alternate
        `
      : "none"};

  ${(props) =>
    props.$isAlignCenter &&
    `
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    z-index: 1004;
  `}
`;

const ContentBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

const BannerFunctionList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 3px 8px;
  height: 25px;

  li {
    .RadioButton {
      font-size: 0;
    }

    span {
      font-size: 13px;
      color: #fff;
    }

    i {
      width: 13px !important;
      height: 13px !important;
      background-position: -23px 0 !important;
      margin: 2px 0;
    }
  }
`;
