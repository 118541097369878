'use client';

import Image from "next/image";
import styles from './footer.module.scss';
import React, { useState } from 'react';
import Link from "next/link";

function FooterInfo() {
  const [isOpen, setIsOpen] = useState(false);

  const businessInfoFunc = () => {
    if (typeof window !== undefined) {
      window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1208709984', '_blank', 'width=650,height=700,scrollbars=yes');
    }
  }

  const openTossAgreement = () => {
    if (typeof window !== undefined) {
      window.open('https://pgweb.uplus.co.kr/ms/escrow/s_escrowYn.do?mertid=champjapan', '', 'scrollbars=no,width=474,height=583');
    }
  }

  return (
    <div className={styles.footerWrap}>
      <div className="inner">
        <div>
          <Image src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/common/img_logo_ca.png`} alt="해커스 감정평가사" width={229} height={27} quality={100} />
        </div>
        <div className={`${styles.footerInfoWrap}`}>
          <div className={styles.footerInfo}>
            <ul className={styles.flnb}>
              <li><Link href={'/'}>Home</Link><span>|</span></li>
              <li><Link href="//www.hackers.com/" target="_blank">해커스소개 </Link><span>|</span></li>
              <li><Link href={`https://member.hackers.com/his_terms?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`} target="_blank">이용약관</Link><span>|</span></li>
              <li><Link href={`https://member.hackers.com/his_policy?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}`} target="_blank" className={styles.textBlue}>개인정보처리방침</Link><span>|</span></li>
              <li><Link href="/cs/faq">고객센터</Link><span>|</span></li>
              <li><Link href="/cs/wishHackers">불편사항 신고</Link></li>
            </ul>

            <address>
              <p>
                (주)챔프스터디 | 사업자등록번호 : 120-87-09984<br />
                온라인 고객센터 : 1588-2332 | 이메일: land@pass.com | FAX : (02)596-5109<br /><br />

                서울특별시 서초구 강남대로61길 23(서초동 1316-15) 현대성우빌딩 203호ㅣ 원격평생교육시설신고(제 원-140호)ㅣ호스팅 제공자 : ㈜KT<br />
                대표이사 : 전재윤ㅣ개인정보관리책임자 : 김병철ㅣ통신판매업신고(제 2008-서울서초-0396호) <button onClick={businessInfoFunc}>정보조회</button>ㅣ부가통신사업신고(신고번호 : 013760)<br />
              </p>

              <p className={styles.copyright}>Copyright © 2003-Present champstudy. All Rights Reserved.</p>
            </address>

            {isOpen &&
              <div className={styles.ismsCertificate}>
                <div className="pos_r">
                  <Image src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/common/ISMS_certificate2.jpg`} alt="ISMS인증서 이미지" width={701} height={996} />
                  <button type="button" onClick={() => setIsOpen(false)} className={styles.btnClose}>X</button>
                </div>
              </div>
            }
          </div>
          <div className={styles.bankInfo}>
            <div className={styles.logo}>
              <Image src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/common/logo_toss.png`} alt="토스 로고" width={128} height={17} />
            </div>
            <div>
              <button className={styles.btnTossOpen} onClick={openTossAgreement}>서비스 가입확인 &gt;</button>
            </div>
            <p className="pt-10">
              고객님은 안전거래를 위해 교재(유료)가
              포함된 상품을 무통장 입금으로 결제하시는
              경우, 챔프스터디가 가입한 Toss Payments
              의 구매안전서비스를 이용하실 수 있습니다.
              토스페이먼츠 결제대금예치업<br />
              등록번호 : 02-006-00059
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterInfo;