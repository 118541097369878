'use client';
import CryptoAES from "@/lib/CryptoAES";
import useUserStore from "@/store/user";
import { SessionProvider, useSession } from "next-auth/react";
import React, { useEffect } from 'react';

declare module "next-auth" {
  interface Session {
    user: string;
  }
}

const crypto = new CryptoAES();

function AuthSession({ children }: { children: React.ReactElement }) {
  const storeUser = useUserStore(state => state.user);
  const setUser = useUserStore(state => state.setUser);
  const { data: session, status } = useSession();
  const sessionData = JSON.parse(crypto.decryptToken(session ? session?.user : ''));

  useEffect(() => {
    if (status === 'authenticated' && sessionData?.idx && !storeUser?.idx) {
      setUser(JSON.parse(crypto.decryptToken(session.user)));
      return;
    }

    if (status === 'unauthenticated') {
      setUser(null);
    }

  }, [session, status, storeUser]);

  return children;
}

function AuthProvider({ children }: { children: React.ReactElement }) {
  return (
    <SessionProvider>
      <AuthSession>
        {children}
      </AuthSession>
    </SessionProvider>
  )
}

export default AuthProvider;