import { UseQueryOptions, useMutation, useQuery } from "react-query";

import { QueryKeys } from "@/assets/ts/queryClient";

import {
  TGetBoard,
  TGetBoardQueryOptions,
  THackersGate,
  TSearchBoardQueryOptions,
  TSearchDisplayBoardOption,
  TSearchPopupBannersOptions,
} from "@/interface/common";

import {
  CreateBoardInput,
  CreateCommentInput,
  UpdateBoardInput,
  UpdateCommentInput,
} from "@/API";

import {
  fetchCreateBoard,
  fetchCreateBoardReply,
  fetchDDay,
  fetchDeleteBoard,
  fetchDeleteBoardReply,
  fetchDisplayBoards,
  fetchGate,
  fetchGetBoard,
  fetchMenus,
  fetchPopupBanners,
  fetchQuickMenu,
  fetchSearchBoards,
  fetchSearchBoardsAdmin,
  fetchUpdateBoard,
  fetchUpdateBoardReply,
} from "./fetch/fetchCommon";

import useUserStore from "@/store/user";

export const useMenus = (filter?: any) => {
  return useQuery([QueryKeys.LIST_MENUS, filter], () => fetchMenus(filter));
};

// 게시판 리스트
export const useListBoard = (
  _from: number,
  _limit: number,
  _typeCode: string,
  _filter?: any,
  options?: TSearchBoardQueryOptions,
  _sort?: any,
) => {
  return useQuery(
    [QueryKeys.LIST_BOARD, _typeCode, _from, _filter, _sort],
    () => fetchSearchBoards(_from, _limit, _typeCode, _filter, _sort),
    options,
  );
};

// 게시판 리스트 어드민
export const useListBoardAdmin = (
  _from: number,
  _limit: number,
  _typeCode: string,
  _filter?: any,
  options?: TSearchBoardQueryOptions,
  _sort?: any,
  _adminCk?: any,
) => {
  return useQuery(
    [QueryKeys.LIST_BOARD, _typeCode, _from, _filter, _sort, _adminCk],
    () => fetchSearchBoardsAdmin(_from, _limit, _typeCode, _filter, _sort, _adminCk),
    options,
  );
};


// 게시판 상세
export const useGetBoard = (_id: string, options?: TGetBoardQueryOptions) => {
  return useQuery(
    [QueryKeys.GET_BOARD, _id],
    () => fetchGetBoard(_id),
    options,
  );
};

// 게시판 삭제
export const useDeleteBoard = () => {
  return useMutation<
    TGetBoard | undefined,
    Error,
    { id: string; _version: number }
  >(({ id, _version }) => fetchDeleteBoard(id, _version));
};

// 게시판 생성
export const useCreateBoard = () => {
  const storeUser = useUserStore((state) => state.user) || {
    id: "",
    idx: "",
    name: "",
  };

  return useMutation(({ params }: { params: Omit<CreateBoardInput, "_version" | "sitesID"> }) => fetchCreateBoard({
    ...params,
    regMemberId: storeUser?.id,
    regMemberIdx: +storeUser?.idx,
    regMemberName: storeUser?.name,
  }));
};

// 게시판 업데이트
export const useUpdateBoard = () => {
  return useMutation<
    TGetBoard | undefined,
    Error,
    { params: UpdateBoardInput }
  >(({ params }) => fetchUpdateBoard(params));
};
//

// 댓글 삭제
export const useDeleteBoardReply = () => {
  return useMutation<
    TGetBoard | undefined,
    Error,
    { id: string; _version: number }
  >(({ id, _version }) => fetchDeleteBoardReply(id, _version));
};

// 댓글 생성
export const useCreateBoardReply = () => {
  const storeUser = useUserStore((state) => state.user) || {
    id: "",
    idx: "",
    name: "",
  };

  return useMutation<
    TGetBoard | undefined,
    Error,
    {
      params: Omit<CreateCommentInput, "_version" | "sitesID" | "regMemberIdx">;
    }
  >(({ params }) =>
    fetchCreateBoardReply({
      ...params,
      regMemberId: storeUser?.id,
      regMemberIdx: +storeUser?.idx,
      regMemberName: storeUser?.name,
    }),
  );
};

// 댓글 업데이트
export const useUpdateBoardReply = () => {
  return useMutation<
    TGetBoard | undefined,
    Error,
    { params: UpdateCommentInput }
  >(({ params }) => fetchUpdateBoardReply(params));
};

// 퀵메뉴
export const useQuickMenu = (_filter?: any, options?: any) => {
  return useQuery(
    [QueryKeys.QUICK_MENUS, _filter],
    () => fetchQuickMenu(_filter),
    options,
  );
};

// 디데이
export const useDDay = (_filter?: any, options?: any) => {
  return useQuery(
    [QueryKeys.DDAY, _filter],
    () => fetchDDay(_filter),
    options,
  );
};

// 팝업 배너
export const usePopupBanners = (
  _filter?: any,
  _sort?: { field: string, direction: "asc" | "desc" }[],
  options?: TSearchPopupBannersOptions,
) => {
  return useQuery(
    [QueryKeys.GET_SEARCH_POPUPBANNER, ...(_sort || []), _filter],
    () => fetchPopupBanners(_filter, _sort),
    options
  );
}

export const useGate = (options?: UseQueryOptions<THackersGate, unknown, THackersGate, string[]>) => {
  return useQuery([QueryKeys.GET_FAMILYGATE], fetchGate, options);
};

// 전광판
/**
 * @param displayBoardCode 전광판 코드 (이벤트 - "이벤트:{이벤트코드}", 메인 - "메인", 수강후기 - "수강후기")
 */
export const useDisplayBoards = (displayBoardCode: string, options?: TSearchDisplayBoardOption) => {
  return useQuery(
    [QueryKeys.GET_SERACH_DISPLAY_BOARD, displayBoardCode],
    () => fetchDisplayBoards(displayBoardCode),
    options
  );
}