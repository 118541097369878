'use client'

import { useClosingBanner } from "@/hook/useFetchIntegration";
import React, { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation'
import Link from "next/link";
import Image from "next/image";
import { ClosingBanner } from "@/interface/banner";
import { getCookie, setCookie } from "@/assets/ts/Utils";
import useMeasure from "react-use-measure";

function ClosingBanner() {
  const pathname = usePathname()
  const searchParams = useSearchParams();
  const [bannerData, setBannerData] = useState<ClosingBanner | null>(null);
  const [isShow, setIsShow] = useState(false);
  const [isAlready, setIsAlready] = useState(false);

  const [ref, { height: viewHeight }] = useMeasure();

  const { data, isSuccess } = useClosingBanner({
    ServiceID: process.env.NEXT_PUBLIC_SERVICE_ID,
    URI: `${pathname}?${searchParams}`,
    Domain: "https://ca.hackers.com/",
    tempTime: ""
  }, {
    enabled: isAlready
  });

  const handleCloseBanner = (day?: number) => {
    if (day) {
      setCookie('ncookie', 'done', day);
    }

    setIsShow(false);
  };

  useEffect(() => {
    const onClosingBanner = (e: any) => {
      if (e.clientY <= 0 && !isAlready) {
        if (!getCookie('ncookie')) {
          setIsAlready(true);

          setTimeout(() => {
            setIsShow(true);
          }, 250);
        }
      }
    }
    window.addEventListener('mouseout', (e) => onClosingBanner(e));

    return window.removeEventListener('mouseout', (e) => onClosingBanner(e));
  }, [isAlready]);

  useEffect(() => {
    if (!isSuccess) return;

    if (data.data?.length > 0) {
      setBannerData(JSON.parse(data.data?.map((banner: any) => banner.BannerSetting)));
    }

  }, [data?.data, isSuccess, isAlready]);


  return (
    <div className="closing-banner-area">
      <div
        className={`${bannerData && bannerData.layoutType} closing-banner-wrap`}
        style={{ top: isShow ? "0px" : "-100%" }}
        ref={ref}
      >
        {bannerData &&
          <>
            <div className={`closing-banner ${bannerData.layoutType}`}>
              <h2 className="closing-title">
                <Link href={bannerData.mainTextLandingURL} target={bannerData.mainImageLandingTarget}>{bannerData.titleText}</Link>
                <button type="button" onClick={() => handleCloseBanner()} className="close" >X</button>
              </h2>
              <div className="top-view">
                <Link href={bannerData.mainImageLandingURL} target={bannerData.mainImageLandingTarget}>
                  <Image src={bannerData.mainImageURL} alt={bannerData.titleText} width={340} height={422} />
                </Link>
              </div>
              <ul className="bottom-view">
                <li>
                  <div className="list-wrap">
                    <div className="view-img img-1">
                      <Link href={bannerData.sub1LandingURL} target={bannerData.sub1LandingTarget}>
                        <Image src={bannerData.sub1ImageURL} alt={bannerData.sub1Text} width={167} height={167} />
                        <div className="view-name name-1">
                          <span className="sub-title-1">
                            {bannerData.sub1Text}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list-wrap">
                    <div className="view-img img-2">
                      <Link href={bannerData.sub2LandingURL} target={bannerData.sub2LandingTarget}>
                        <Image src={bannerData.sub2ImageURL} alt={bannerData.sub2Text} width={167} height={167} />
                        <div className="view-name name-2">
                          <span className="sub-title-2">
                            {bannerData.sub2Text}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="cookies">
              {bannerData.cookieSetupCheck.includes('today') &&
                <div className="closing-panel">
                  <button type="button" onClick={() => handleCloseBanner(1)}>오늘 하루 그만보기</button>
                </div>
              }
              {bannerData.cookieSetupCheck.includes('weekend') &&
                <div className="closing-panel">
                  <button type="button" onClick={() => handleCloseBanner(7)}>7일간 그만보기</button>
                </div>
              }
            </div>
          </>
        }
      </div>
      {bannerData?.layoutDimmed == 'true' && isShow && <div className="dimmed" onClick={() => handleCloseBanner()}></div>}
    </div>
  );
}

export default ClosingBanner;